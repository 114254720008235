import React, {useEffect, useRef, useState} from "react";
import {faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFetch from "../../hooks/useFetch";
import FsLightbox from 'fslightbox-react';

export const TicketFilesBlock : React.FunctionComponent<any> = props => {
    const [files, setFiles] = useState<any>([]);
    const [types, setTypes] = useState<any>([]);
    const [fullFiles, setFullFiles] = useState<any>([]);
    const [getFilesResult, fetchFiles] = useFetch('getfiles/'+props.ticket);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        if (!getFilesResult.responses) return
        let filesArray: string[] = [];
        let typesArray: string[] = [];
        const ff:any = getFilesResult.responses['files'];
        if(ff) ff.map(function(item: any, i: number){
            if(item['format'] == 'image' || item['format'] == 'video') {
                filesArray.push(item['url'])
                typesArray.push(item['format'])
            }
        })
        setFiles(filesArray)
        setTypes(typesArray)
        setFullFiles(getFilesResult.responses['files'])
    }, [getFilesResult.responses])

    useEffect(() => {
        if(props.ticket) refreshFiles()
    }, [props.ticket])

    const refreshFiles = () => {
        fetchFiles({
            method: 'POST',
            data: {}
        })
    }
    return (
        <div>
            <div className="mt-3">
                { fullFiles.length > 0 && <div className="font-bold text-sm">Attachments:</div> }
                <div className="mt-1 text-left">
                    {
                        fullFiles && fullFiles.map(function (item: any, i: number) {
                            const outerStyle="inline-block h-20 w-auto p-2 m-1 cursor-pointer bg-gray-100 rounded-xl align-top";
                            if (item['format'] == 'image') {
                                return <div key={i} className={outerStyle} onClick={() => openLightboxOnSlide(i+1)}>
                                    <img src={item.url} className="h-full"/>
                                </div>
                            } else if (item['format'] == 'video') {
                                return <div key={i} className={outerStyle} onClick={() => openLightboxOnSlide(i+1)}>
                                    <FontAwesomeIcon className=" text-5xl text-gray-400 mt-2 mx-2" icon={faVideo} />
                                </div>
                            }
                        })
                    }
                </div>

                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={files}
                    types={types}
                    slide={lightboxController.slide}
                />

            </div>
        </div>
    )

    function useInterval(callback: any, delay: any) {
        const savedCallback: any = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
}