import * as React from 'react';
import { Page } from '../page';
import {TicketForm} from "../../components/ticket/TicketForm";

const TicketAdd = () => {
  return (
      <Page title='Add Ticket' tabNavigation={true} header={true}>
        <TicketForm />
      </Page>
  );
}

export default TicketAdd;