import React, {useEffect, useState} from "react";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import EasyEdit, {Types} from 'react-easy-edit';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useFetch from "../hooks/useFetch";
import {Btn} from "./PageComponents";
import {Link} from "react-router-dom";
export const TenantInfo : React.FunctionComponent<any> = props => {
    let tenant = props.data;

    return (
        tenant &&
        <div className="">
            <div className="m-auto mt-5 mb-3">
                <img src={tenant.img} className="w-full rounded-full w-60 h-60 sm:w-72 sm:h-72 md:w-96 md:h-96 overflow-hidden m-auto"></img>
            </div>
            <div className="text-center text-lg font-bold text-slate-900">
                {tenant.first_name + " " + tenant.last_name}
            </div>
            <div className="flex flex-row items-center text-slate-900">
                <Btn className="flex-1">Receive Payment</Btn>
                <Btn className="flex-1">Add Charge</Btn>
                <Btn className="flex-1 middle">Add Note</Btn>
            </div>
            <div className="flex flex-row items-center text-slate-900 mt-2">
                <div className="flex-1 text-left p-2 pl-5 cursor-pointer" onClick={() => window.location.href = 'tel:'+tenant.phone}>
                    <div>Phone Number</div>
                    <div className="text-gray-500">{tenant.phone}</div>
                </div>
                <div className="flex-1 text-left p-2 pl-5 cursor-pointer" onClick={() => window.location.href = 'mailto:'+tenant.email}>
                    <div>Email</div>
                    <div className="text-gray-500">{tenant.email}</div>
                </div>
            </div>
            <div className="flex-grow border-t border-gray-300 mt-1"></div>
            <div className="flex flex-row items-center text-slate-900">
                <div className="flex-1 text-left p-2 pl-5">
                    <div>Balance</div>
                    <div className="text-gray-500">{tenant.balance}</div>
                </div>
                <div className="flex-1 text-left px-2 pl-1">
                    <Btn className="w-full max-w-sm bg-inherit p-1">View Ledger</Btn>
                    <Btn className="w-full max-w-sm bg-inherit p-1">Send Statement</Btn>
                </div>
            </div>
            <div className="flex-grow border-t border-gray-300 mt-1"></div>
            <div className="flex flex-row items-center text-slate-900">
                <div className="flex-1 text-left p-2 pl-5">
                    <div>Open tickets</div>
                    <div className="text-gray-500">{tenant.ticketnum}</div>
                </div>
                <div className="flex-1 text-left px-2 pl-1">
                    <Link to={"/tickets/tenant="+tenant.id+";status=1,2,3,4"}><Btn className="w-full max-w-sm bg-inherit p-1">View Tickets</Btn></Link>
                </div>
            </div>
        </div>

    )
}
