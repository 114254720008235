import * as React from 'react'
import { createContext, useReducer } from 'react'

export interface State {
    isLoading: boolean;
    isLoggedIn: boolean | null;
    currentUser: any;
}

const initialState: State = {
    isLoading: false,
    isLoggedIn: null,
    currentUser: {}
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                isLoading: true
            }
        case 'SET_AUTHORIZED':
            return {
                ...state,
                isLoggedIn: true,
                isLoading: false,
                currentUser: action.payload
            }
        case 'SET_UNAUTHORIZED':
            return {
                ...state,
                isLoggedIn: false,
                currentUser: null
            }
        default: return state
    }
}

export const CurrentUserContext = createContext<any>(null);

export const CurrentUserProvider = ({ children }: { children: any }) => {

    const value = useReducer(reducer, initialState)
    return (
        <CurrentUserContext.Provider value={value}>
            {children}
        </CurrentUserContext.Provider>
    )
}