import React, { useEffect, useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import {useParams} from "react-router-dom";


export const TicketsBlock: React.FunctionComponent<any> = props => {
    const [currentFilter, setCurrentFilter] = useState<string>('')
    const filterMenuBlockClass = "relative text-white px-2 py-1.5 rounded bg-blue-400 inline-block m-1.5 cursor-pointer text-sm";
    const filterWindowBlockClass = "flex flex-row pl-4 text-left cursor-pointer border-b-2 p-2 border-gray-100";
    const filterNames = [ { key: "status", name: "Status" }, { key: "assigned_to", name: "Assigned To" }, { key: "property_id", name: "Property" }, { key: "due_date_calc", name: "Due" }, { key: "priority", name: "Priority" }, { key: "tags", name: "Tags" }, { key: "tenant", name: "Tenant" }];
    const [activeFilters, setActiveFilters] = useState<any>(null)
    const [parsed, setParsed] = useState<boolean>(false)
    const [tickets, setTickets] = useState<[]>([]);
    const [filters, setFilters] = useState<[]>([]);
    const [icon, setIcon] = useState<any>(faSquare);
    const [{ responses, isLoading }, doFetch] = useFetch('tickets');
    const [lsFilters, setLsFilters] = useLocalStorage('filters')
    let urlFilters = useParams();
    const loading = require("../../assets/loading.gif");

    useEffect(() => {
        if (!responses) return
        setTickets(responses['tickets'])
        setFilters(responses['filters'])
    }, [responses])

    useEffect(() => {
        if (activeFilters !== null) {
            doFetch({
                method: 'post',
                data: {filters: activeFilters}
            })
        }
    }, [activeFilters, parsed])

    useEffect(() => {
        parseUrl();
    }, [])

    useEffect(() => {
        if(parsed || !(urlFilters && urlFilters['filters'])) {
            if (lsFilters)
                setActiveFilters(JSON.parse(lsFilters));
            else setActiveFilters([]);
        }
    }, [lsFilters])

    const parseUrl = () => {
        if(urlFilters && urlFilters['filters']) {
            let urlfs = urlFilters['filters'].split(';');
            let aFilters = Array();
            urlfs.map(function (item: any, i: number) {
                let urlfParts = item.split('=');
                if(!aFilters[urlfParts[0]]) aFilters[urlfParts[0]] = Array();
                let urlfValues = urlfParts[1].split(',');
                urlfValues.map(function (value: any, i: number) {
                    aFilters[urlfParts[0]].push(value);
                })
            })
            //setLsFilters(JSON.stringify(Object.assign({}, aFilters)));
            setActiveFilters(JSON.parse(JSON.stringify(Object.assign({}, aFilters))));
            setParsed(true);
            return true;
        } else {
            setParsed(true);
            return false;
        }
    }

    const toggleFilter = (field: string) => {
        if (currentFilter != field) setCurrentFilter(field);
        else setCurrentFilter('');
    }

    const toggleAllChecks = (field: string) => {
        let data = (filters.find((o: any) => o.field == currentFilter)) || [];
        activeFilters[field] = [];
        if (icon == faSquareCheck) {
            setIcon(faSquare);
        } else {
            setIcon(faSquareCheck);
            data && data['data'].map(function (item: any, i: number) {
                activeFilters[field].push(item.value+"");
            })
        }
        setActiveFilters(activeFilters);
        setLsFilters(JSON.stringify(activeFilters));
    }

    const toggleOption = (field: string, value: string) => {
        let aFilters = activeFilters;
        setIcon(faCheck);
        if (!aFilters[field]) aFilters[field] = [];
        if (aFilters[field].includes(value+"")) {
            const index = aFilters[field].indexOf(value+"");
            if (index > -1) {
                aFilters[field].splice(index, 1);
            }
        } else {
            aFilters[field].push(value+"");
        }
        setLsFilters(JSON.stringify(Object.assign({}, aFilters)));
        setActiveFilters(JSON.parse(JSON.stringify(Object.assign({}, aFilters))));
    }

    const TicketsFilters: React.FunctionComponent<any> = props => (
        <div className="w-full mt-10 mb-5 -z-10">
            {
                activeFilters !== null &&
                filterNames.map(function (item: any, i: number) {
                    return (
                        <div key={i} className={filterMenuBlockClass + (activeFilters[item.key] && activeFilters[item.key].length > 0 ? ' bg-blue-500' : '') + (props.active == item.key ? ' border border-blue-800' : '')} onClick={(e) => toggleFilter(item.key)}>
                            {item.name}
                            {activeFilters[item.key] && activeFilters[item.key].length > 0 && <div className="absolute -right-3 -top-3 bg-blue-900 p-1 rounded-full text-xxs leading-tight">
                                <div className="inline-block" style={{ 'marginRight': '2px' }}>{activeFilters[item.key].length}</div>
                                <FontAwesomeIcon className="inline-block" icon={faFilter} />
                            </div>}
                        </div>
                    )
                })
            }
            <Link to={"/tickets/add"}>
                <div key={0} className={filterMenuBlockClass + " bg-green-600 ml-3 px-4"}>
                    <FontAwesomeIcon className="inline-block" icon={faPlus} />
                </div>
            </Link>
        </div>
    );

    const TicketFilter: React.FunctionComponent<any> = props => {
        let className = "fixed bottom-0 pt-3 rounded-3xl border-t-4 border-top border-slay-800 w-full h-3/4 bg-white bottom-0 left-0" + (props.status ? "" : " hidden")
        let f = (filterNames.find(o => o.key == currentFilter));
        let data = (props.filters.find((o: any) => o.field == currentFilter));
        return (
            <div>
                <div className={"absolute w-screen left-0 h-1/2 top-0 -ml-1 " + (props.status ? "" : " hidden")} onClick={(e) => toggleFilter('')}></div>

                <div className={className} id="filterPopup">
                    <FontAwesomeIcon className="absolute left-4 cursor-pointer text-gray-400 text-2xl" icon={icon} onClick={(e) => toggleAllChecks(data.field)} />

                    <FontAwesomeIcon className="absolute right-3 cursor-pointer text-gray-400" icon={faXmark} onClick={(e) => toggleFilter('')} />
                    <div className="mb-2 text-gray-400">{f ? f['name'] : ''}</div>
                    <div className="h-full overflow-y-scroll pb-8">
                        {
                            data && data['data'].map(function (item: any, i: number) {
                                return (
                                    <div key={i} className={filterWindowBlockClass} onClick={(e) => { e.preventDefault(); toggleOption(data.field, item.value) }}>
                                        <div className="flex-1">{item.name}</div>
                                        <div className="flex-initial" >
                                            <FontAwesomeIcon className={activeFilters[data.field] && activeFilters[data.field].includes(item.value+"") ? '' : ' hidden'} icon={faCheck} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={"absolute w-screen h-3/4 bottom-0 left-0 bg-slate-200 opacity-50 -ml-1 " + (isLoading && currentFilter ? "" : " hidden")}>
                    <img src={loading} className="w-10 m-auto mt-[30vh]"></img>
                </div>

            </div>
        )
    }

    const TicketsList: React.FunctionComponent<any> = props => (
        <div className="max-w-screen-md mx-auto">
            {
                props.tickets.length != 0 && props.tickets.map(function (item: any, i: number) {
                    let cl = "m-5 mb-3 mt-1 rounded p-4 text-sm cursor-pointer ";
                    switch (item.status) {
                        case '1': cl += "bg-status1 text-tstatus1"; break;
                        case '2': cl += "bg-status2 text-tstatus2"; break;
                        case '3': cl += "bg-status3 text-tstatus3"; break;
                        case '4': cl += "bg-status4 text-tstatus4"; break;
                        case '5': cl += "bg-status5 text-tstatus5"; break;
                    }
                    return (
                        <Link key={i} to={"/ticket/" + item.id}>
                            <div  className={cl}>
                                <div className="flex flex-row font-bold">
                                    <div className="flex-1 text-left">{item.title}</div>
                                    <div className="flex-initial">{item.propname}</div>
                                </div>
                                <div className="flex flex-row font-bold mt-1">
                                    <div className="flex-initial text-left mr-1 text-gray-600 ">{item.create_date} |</div>
                                    <div className="flex-initial max-w-40% text-left whitespace-nowrap overflow-hidden text-ellipsis  mr-1"> {item.statustext}</div>
                                    <div className="flex-1 text-left whitespace-nowrap text-gray-600 text-ellipsis overflow-hidden">| {item.description}</div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    );

    return (
        <div>
            <TicketsFilters active={currentFilter} />
            <TicketFilter status={currentFilter} filters={filters} />
            <TicketsList tickets={tickets}></TicketsList>
        </div>
    )
}
