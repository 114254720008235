import * as React from 'react';
import { Page } from '../page';
import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";

import {useParams} from "react-router";
import {TicketInfo} from "../../components/ticket/TicketInfo";
import {TicketFilesBlock} from "../../components/ticket/TicketFilesBlock";
import {TicketMessageBlock} from "../../components/ticket/MessageBlock";


const TicketView = () => {
    let { id } = useParams();
    const [ticket, setTicket] = useState<any>(null);
    const [messages, setMessages] = useState<any>(null);
    const [{ responses }, doFetch] = useFetch('ticket/'+id);

    useEffect(() => {
        if (!responses) return
        setTicket(responses['ticket'])
        setMessages(responses['messages'])
    }, [responses])

    useEffect(() => {
        doFetch({
            method: 'post',
            data: { }
        })
    }, [])
  return (
      <Page title='Ticket View' tabNavigation={true} header={true}>
        <TicketInfo data={ticket}/>
        <TicketFilesBlock ticket={ticket && ticket.id}/>
        <TicketMessageBlock messages={messages} tenant={ticket && ticket.tenant} ticket={ticket && ticket.id}/>
      </Page>
  );
}

export default TicketView;