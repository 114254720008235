import * as React from 'react';
import { Page } from '../page';
import { useContext, useEffect, useState } from "react";

import {Navigate} from "react-router-dom";
import {CurrentUserContext} from "../../contexts/currentUser";


const HomePage = () => {
  const [currentUserState] = useContext(CurrentUserContext);
  const [tokenUpdate, seTokenUpdate] = useState<boolean>(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!tokenUpdate) return;
    window.location.reload();
  }, [tokenUpdate])

  useEffect(() => {
    if(currentUserState.isLoggedIn === true) {
      setRedirect(true);
    }

  }, [currentUserState]);

  return (
      <Page title='Home' tabNavigation={true}>
        {redirect && <Navigate to={'/tickets'}/>}
      </Page>
  );
}

export default HomePage;