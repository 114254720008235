import React from "react";
import {NavLink} from "react-router-dom";

export const PageWrapper : React.FunctionComponent<any> = props => (
    <div className="w-full h-full">
        { props.children }
    </div>
);

export const PageContainer : React.FunctionComponent<any> = props => (
    <div className="w-full h-screen box-border">
        { props.children }
    </div>
);

export const Btn : React.FunctionComponent<any> = props => (
    <div className={"p-2 bg-slate-200 cursor-pointer m-1 rounded " + props.className}>
        { props.children }
    </div>
);

const logo = require("../assets/logo.png");
export const PageHeader : React.FunctionComponent<any> = props => (
    <div className="w-full fixed top-0 left-0 bg-white drop-shadow-md z-100">
        <NavLink to={"/"}><img src={logo} className="h-9 m-2 float-left"></img></NavLink>
        { props.name && <div className="float-right mt-3 mr-3 p-2 bg-gray-200 rounded-lg leading-3 text-base">Welcome, <span className="font-medium">{props.name}</span></div> }
        { props.children }
    </div>
);

export const PageContent : React.FunctionComponent<any> = props => (
    <div className={"w-full md:w-5/6 lg:w-2/3 xl:1/2 m-auto h-full p-1 " + (props.header ? "pt-12" : "")}>
        { props.children }
    </div>
);

export const Alert : React.FunctionComponent<any> = props => {
    let c = props.type ? 'bg-green-100 rounded-lg py-5 px-6 mb-4 text-base text-green-700 mb-3' : 'bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 mb-3';
    return(
        <div className={c} role="alert">
            { props.children }
        </div>
    )
};

/*export const PageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;    
`;

export const PageWrap = styled.div`
  width: 100%;
  height: 100%;
  margin:auto;
  overflow-y: scroll;
  & > div { max-width:1200px; margin:auto; }
`;

export const PageContent = styled.div`
  width: 100%;
  position: relative;
  top: -25px;  
`;*/