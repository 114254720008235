import * as React from 'react';
import { Page } from '../page';
import {TicketsBlock} from "../../components/ticket/TicketsBlock";



const TicketsPage = () => {
  return (
      <Page title='Tickets List' tabNavigation={true} header={true}>
        <TicketsBlock />
      </Page>
  );
}

export default TicketsPage;