import { default as config } from '../config.json';
import axios from 'axios';
import useLocalStorage from "./useLocalStorage";
import { useEffect, useState, useCallback, useContext } from 'react';
import { CurrentUserContext } from "../contexts/currentUser";

const useFetch = <T>(url: string) => {

    const baseUrl = config.BASE_API_URL;
    const requestUrl = url.includes('http') ? url : baseUrl + url + '/';
    const [responses, setResponses] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState(null);
    const [options, setOptions] = useState<any>({});
    const [token, setToken] = useLocalStorage('token');
    const [, dispatch] = useContext(CurrentUserContext);
    const controller = new AbortController();

    let headers: any = !url.includes('http') ?
        {
            headers: {
                Authorization: token ? `Token ${token}` : '',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        : {}

    const doFetch = useCallback((options: {} = {}) => {
        setOptions(options)
        setIsLoading(true)
    }, [])

    useEffect(() => {
        if (options.ctype) headers.headers['Content-Type'] = options.ctype;

        const requestOptions = { ...headers, ...options, signal: controller.signal }

        if (!isLoading) return;
        axios(requestUrl, requestOptions)
            .then(response => {
                setIsLoading(false);
                setErrors(null);
                setResponses(response.data);
            })
            .catch(err => {
                setIsLoading(false);
                if (err.response && err.response.status === 401) {
                    setToken('');
                    dispatch({ type: 'SET_UNAUTHORIZED' })
                }
                if(err.response) {
                    setResponses(null);
                    setErrors(err.response.data);
                }

            });

        return () => { };

    }, [isLoading]);

    return [{ responses, isLoading, errors }, doFetch] as const;
};

export default useFetch;