import React, {useEffect, useState} from "react";
import {faEnvelope, faPhone, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useFetch from "../../hooks/useFetch";
import Select, {ActionMeta, OnChangeValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {Link, useNavigate} from "react-router-dom";
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const TicketForm : React.FunctionComponent<any> = props => {
    const [propertyResponse, getProperties] = useFetch('getSelectData');
    const [unitResponse, getUnits] = useFetch('getSelectData');
    const [tenantResponse, getTenants] = useFetch('getSelectData');
    const [assignedResponse, getAssigned] = useFetch('getSelectData');
    const [tagsResponse, getTags] = useFetch('getSelectData');
    const [optionsResponse, getOptions] = useFetch('getSelectData');
    const [saveResponse, saveData] = useFetch('add');
    let [property, setProperty] = useState<any>();
    let [unit, setUnit] = useState<any>();
    let [tenant, setTenant] = useState<any>();
    let [tags, setTags] = useState<any>();
    let [propertyLoading, setPropertyLoading] = useState<any>();
    let [unitLoading, setUnitLoading] = useState<any>();
    let [tenantLoading, setTenantLoading] = useState<any>();
    let [assignedLoading, setAssignedLoading] = useState<any>();
    let [tagsLoading, setTagsLoading] = useState<any>();
    const navigate = useNavigate();
    const [dueDate, setDueDate] = useState(new Date());
    const loading = require("../../assets/loading.gif");

    const formik = useFormik({
        initialValues: {
            title: '', description: '', category: null, status: null, priority: null, message: '', internal: false, property: null, unit: null, tenant: null, lease_id: null, assigned_to: null, tags:''
        },
        onSubmit: values => {
            saveData({method: 'POST', data: {data :values}});
        },
    });

    useEffect(() => {
        getProperties({method: 'POST', data: {'field' : 'property'}});
        getUnits({method: 'POST', data: {'field' : 'unit'}});
        getTenants({method: 'POST', data: {'field' : 'tenant'}});
        getAssigned({method: 'POST', data: {'field' : 'assigned'}});
        getTags({method: 'POST', data: {'field' : 'tags'}});
        getOptions({method: 'POST', data: {'field' : null}});
        setUnitLoading(true);
        setTenantLoading(true);
        setPropertyLoading(true);
        setAssignedLoading(true);
        setTagsLoading(true);
    }, [])

    useEffect(() => {
        if(saveResponse.responses) {
            window.location.href = '/tickets';
        }
    }, [saveResponse])
    useEffect(() => {
        if(propertyResponse.responses) setPropertyLoading(false);
    }, [propertyResponse])
    useEffect(() => {
        if(unitResponse.responses) setUnitLoading(false);
    }, [unitResponse])
    useEffect(() => {
        if(tenantResponse.responses) setTenantLoading(false);
    }, [tenantResponse])
    useEffect(() => {
        if(assignedResponse.responses) setAssignedLoading(false);
    }, [assignedResponse])
    useEffect(() => {
        if(tagsResponse.responses) setTagsLoading(true);
    }, [tagsResponse])
    useEffect(() => {
        property && formik.setFieldValue("property", property.value)
    }, [property])
    useEffect(() => {
        unit && formik.setFieldValue("unit", unit.value)
    }, [unit])
    useEffect(() => {
        if(tenant) {
            formik.setFieldValue("tenant", tenant.value);
            formik.setFieldValue("lease_id", tenant.lid);
        }
    }, [tenant])
    useEffect(() => {
        tags && formik.setFieldValue("tags", tags.value)
    }, [tags])

    const propertyChange = (value: any) => {
        setProperty(value);
        getUnits({method: 'POST', data: {'field' : 'unit', 'filters': {'property' : value}}});
        getTenants({method: 'POST', data: {'field' : 'tenant', 'filters': {'property' : value}}});
        setUnitLoading(true);
        setTenantLoading(true);
        if(unit && unit.pid != value.value) setUnit(null);
        if(tenant && tenant.pid != value.value) setTenant(null);
    }
    const unitChange = (value: any) => {
        setUnit(value);
        getTenants({method: 'POST', data: {'field' : 'tenant', 'filters': {'unit' : value}}});
        setTenantLoading(true);
        setProperty({value: value.pid, label: value.pname});
        if(tenant && tenant.unit_id != value.value) setTenant(null);
    }
    const tenantChange = (value: any) => {
        setTenant(value);
        //console.log(value);
        setUnit({value: value.unit_id, label: value.unit_name});
        setProperty({value: value.pid, label: value.pname});
    }
    const assignedChange = (value: any) => {
        value && formik.setFieldValue("assigned_to", value.value)
    }
    const tagsChange = (newValue: OnChangeValue<any, true>,
                        actionMeta: ActionMeta<any>) => {
        newValue && formik.setFieldValue("tags", newValue)
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: '2px',
            borderColor: '#e5e7eb',
            margin: '6px 0'
        })
    }
    const inputStyles = "border-2 rounded ml-0 w-full p-1 my-1";
    const inputLabelStyles = "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-0 mt-3";

    return (
        <div>
            <div className="text-blue-900 p-1 mt-5 mb-3 text-left cursor-pointer" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> All Tickets
            </div>
            <form onSubmit={formik.handleSubmit} className="text-left w-full max-w-lg m-auto px-3 pb-3">
                <label htmlFor="title" className={inputLabelStyles}>Title</label>
                <input
                    id="title"
                    name="title"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    className={inputStyles}
                />

                <label htmlFor="description" className={inputLabelStyles}>Description</label>
                <textarea
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    className={inputStyles}
                ></textarea>

                <div className="flex flex-wrap -mx-3 mb-2 mt-4">
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Category</label>
                        <Select
                            styles={customStyles}
                            options={optionsResponse && optionsResponse.responses ? optionsResponse.responses['categories'] : []}
                            onChange={(value) => {value && formik.setFieldValue("category", value['value'])}}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Status</label>
                        <Select
                            styles={customStyles}
                            options={optionsResponse && optionsResponse.responses ? optionsResponse.responses['statuses'] : []}
                            onChange={(value) => {value && formik.setFieldValue("status", value['value'])}}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label  className={inputLabelStyles}>Priority</label>
                        <Select
                            styles={customStyles}
                            options={optionsResponse && optionsResponse.responses ? optionsResponse.responses['priorities'] : []}
                            onChange={(value) => {value && formik.setFieldValue("priority", value['value'])}}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Due date</label>
                        <DatePicker style={{marginTop: '6px !important'}} className={inputStyles + " text-gray-800 h-10"} selected={dueDate} onChange={(date:Date) => {formik.setFieldValue("dueDate", date); setDueDate(date)}} />
                    </div>
                </div>
                <hr className="border-t-2"/>
                <div className="flex flex-wrap -mx-3 mb-2 mt-2">
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Building</label>
                        <Select
                            styles={customStyles}
                            value={property}
                            options={propertyResponse && propertyResponse.responses ? propertyResponse.responses['data'] : []}
                            onChange={propertyChange}
                            isDisabled={propertyLoading}
                            isLoading={propertyLoading}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Unit</label>
                        <Select
                            styles={customStyles}
                            value={unit}
                            options={unitResponse && unitResponse.responses ? unitResponse.responses['data'] : []}
                            onChange={unitChange}
                            isDisabled={unitLoading}
                            isLoading={unitLoading}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Tenant</label>
                        <Select
                            styles={customStyles}
                            value={tenant}
                            options={tenantResponse && tenantResponse.responses ? tenantResponse.responses['data'] : []}
                            onChange={tenantChange}
                            isDisabled={tenantLoading}
                            isLoading={tenantLoading}
                        />
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0">
                        <label className={inputLabelStyles}>Assigned to</label>
                        <Select
                            styles={customStyles}
                            options={assignedResponse && assignedResponse.responses ? assignedResponse.responses['data'] : []}
                            onChange={assignedChange}
                            isDisabled={tenantLoading}
                            isLoading={tenantLoading}
                        />
                    </div>
                </div>
                <hr className="border-t-2"/>
                <label className={inputLabelStyles}>Tags</label>
                <CreatableSelect
                    onChange={tagsChange}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    defaultValue={[]}
                    isMulti
                    options={tagsResponse && tagsResponse.responses ? tagsResponse.responses['data'] : []}
                />

                <label htmlFor="message" className={inputLabelStyles}>Initial Message</label>
                <textarea
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    className={inputStyles}
                ></textarea>

                <div className="flex flex-wrap -mx-3 mt-2">
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0 flex flex-row items-center">
                        <input
                            type="checkbox"
                            id="internal"
                            name="internal"
                            onChange={formik.handleChange}
                            checked={formik.values.internal}
                        ></input>
                        <label htmlFor="internal" className={inputLabelStyles + " inline-block mt-0 ml-1"}>Internal</label>
                    </div>
                    <div className="w-full sm:w-1/2 px-3 mb-1 md:mb-0 text-right">
                        <img className={"w-6 mr-2 inline-block" + (saveResponse.isLoading ? '' : ' hidden')} src={loading}/>
                        <button type="submit" disabled={(saveResponse.isLoading ? true : false)} className="disabled:opacity-50 inline-block px-5 py-1 bg-slate-900 text-white rounded font-bold">CREATE TICKET</button>
                    </div>
                </div>

            </form>
        </div>
    )
}
