import * as React from 'react';
import './App.css';
import { CurrentUserProvider } from "./contexts/currentUser";
import CurrentUserChecker from './utils/currentUserChecker';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/home/home";
import LoginPage from "./pages/login/login";
import "./index.css";
import TicketsPage from "./pages/tickets";
import TicketView from "./pages/tickets/view";
import TenantView from "./pages/tenant";
import TicketAdd from "./pages/tickets/add";
const App = () => {

  return (
    <CurrentUserProvider>
      <CurrentUserChecker>
        <div className="text-center">
          <Router>
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/tickets' element={<TicketsPage />} />
              <Route path='/tickets/:filters' element={<TicketsPage />} />
              <Route path='/tickets/add' element={<TicketAdd />} />
              <Route path='/ticket/:id' element={<TicketView />} />
              <Route path='/tenant/:id' element={<TenantView />} />
            </Routes>
          </Router>
        </div>
      </CurrentUserChecker>
    </CurrentUserProvider>
  );
}

export default App;
