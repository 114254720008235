import * as React from 'react';
import { Page } from './page';
import useFetch from "../hooks/useFetch";
import { useEffect, useState } from "react";

import {useParams} from "react-router";
import {TenantInfo} from "../components/TenantInfo";

const TenantView = () => {
    let { id } = useParams();
    const [tenant, setTenant] = useState<any>(null);
    const [{ responses }, doFetch] = useFetch('tenant/'+id);

    useEffect(() => {
        if (!responses) return
        setTenant(responses['tenant'])
    }, [responses])

    useEffect(() => {
        doFetch({
            method: 'post',
            data: { }
        })
    }, [])
  return (
      <Page title='Ticket View' tabNavigation={true} header={true}>
        <TenantInfo data={tenant}/>
      </Page>
  );
}

export default TenantView;