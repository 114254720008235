import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import { Page } from '../page';
import useFetch from '../../hooks/useFetch'
import useLocalStorage from '../../hooks/useLocalStorage'
import {CurrentUserContext} from "../../contexts/currentUser";
import {Navigate} from "react-router";
import {Alert, PageWrapper} from "../../components/PageComponents";

const LoginPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [redirect, setRedirect] = useState<any>(null)
  const [{responses, errors, isLoading}, doFetch] = useFetch('login');
  const [, setToken] = useLocalStorage('token')
  const [, dispatch] = useContext(CurrentUserContext)

  const handleLogin = () => {
    const user = { email, password }
    doFetch({
      method: 'post',
      data: { user }
    })
  }

  const onKeyDownHandler = (e: { keyCode: number; }) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  useEffect(() => {
    if (!responses) return
    setToken(responses['token'])
    dispatch({ type: 'SET_AUTHORIZED', payload: responses['user'] || null})
    setRedirect(<Navigate to={'/'}/>);
  }, [responses, setToken])

  return (
  <Page title='Manager Login' tabNavigation={false} header={true} checkAuth={false}>
    {redirect}
    <section className="h-full">

      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center justify-center items-center flex-wrap h-full g-6">
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            {(errors || responses) && <Alert type={errors ? 0 : 1}>{errors ? errors!['message'] : responses!['message']}</Alert>}

            <form>
              <div className="mb-6">
                <input
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="email"
                    placeholder="Email address"
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={onKeyDownHandler}
                />
              </div>
              <div className="mb-6">
                <input
                    type="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="password"
                    placeholder="Password"
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={onKeyDownHandler}
                />
              </div>
              <div className="flex justify-between items-center mb-6">
                <div className="form-group form-check">
                  <input
                      type="checkbox"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      id="exampleCheck2"
                  />
                  <label className="form-check-label inline-block text-gray-800" htmlFor="exampleCheck2"
                  >Remember me</label
                  >
                </div>
                <a href="#!" className="text-gray-800">Forgot password?</a>
              </div>

              <div className="text-center">
                <button
                    type="button"
                    onClick={handleLogin}
                    className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Page>
);
}
export default LoginPage;