import React, {useEffect, useRef, useState} from "react";
import {faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFetch from "../../hooks/useFetch";

export const TicketMessageBlock : React.FunctionComponent<any> = props => {
    const [messages, setMessages] = useState<any>();
    const [lastMId, setLastMId] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const [messageResult, fetchMessage] = useFetch('addmessage');
    const [getMessagesResult, fetchMessages] = useFetch('getmessages/'+props.ticket);
    const messagesEnd: React.RefObject<any> = React.createRef();

    useEffect(() => {
        if (!messageResult.responses) return
        setMessage('')
        refreshMessages()
    }, [messageResult.responses])

    useEffect(() => {
        if (!getMessagesResult.responses) return
        if(getMessagesResult.responses['newMessages'] > 0) {
            setLastMId(getMessagesResult.responses['newMessages']);
            setMessages(getMessagesResult.responses['messages']);
        }
    }, [getMessagesResult.responses])

    useEffect(() => {
        messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages])

    const refreshMessages = () => {
        fetchMessages({
            method: 'POST',
            data: {last: lastMId}
        })
    }

    const onKeyDownHandler = (e: { keyCode: number; }) => {
        if (e.keyCode === 13) {
            sendMessage();
        }
    };

    useInterval(() => {
       refreshMessages()
    }, 10000);

    useEffect(() => {
        if(!props.messages) return;
        setMessages(props.messages);
        if(props.messages.length) setLastMId(props.messages[props.messages.length-1]['id'])
        //messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [props.messages])

    const sendMessage = () => {
        if (!message) return;
        fetchMessage({
            method: 'POST',
            data: { tid: props.ticket, message: message }
        })
    }

    return (
        <div>
            <div className="max-w-screen-md mx-auto pb-20 mt-1">
                {
                    messages && messages.map(function(item: any, i: number){
                        return (
                            <div key={i} className={"rounded-md p-2 mb-2 "+(item.profile_id == props.tenant ? "text-purple-800 bg-purple-100 ml-20" : "text-green-800 bg-lime-100 mr-20")}>
                                <div className="flex flex-row font-bold text-sm">
                                    <div className="flex-1 text-left">{item.name}</div>
                                    <div className="flex-initial">{item.date}</div>
                                </div>
                                <div className="text-slate-600 text-left m-1 text-sm">{item.text}</div>
                            </div>
                        )
                    })
                }
                <div style={{ float:"left", clear: "both" }}
                     ref={ messagesEnd }>
                </div>
            </div>
            <div className="fixed bottom-0 w-full md:w-5/6 lg:w-2/3 xl:1/2 p-3 bg-white">
                <textarea onKeyDown={onKeyDownHandler} value={message} id="message" className="overflow-auto p-2 bg-slate-100 w-full resize-none pr-16 text-slate-600 rounded-xl h-16" placeholder="Type Your Message Here" onChange={e => setMessage(e.target.value)}/>
                <FontAwesomeIcon className="absolute right-8 top-7 cursor-pointer text-blue-400 text-3xl" icon={faPaperPlane} onClick={(e) => sendMessage()} />

            </div>
        </div>
    )

    function useInterval(callback: any, delay: any) {
        const savedCallback: any = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
}
