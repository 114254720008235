import * as React from 'react';
//import { PageContainer, PageContent, PageWrap } from './page.styles';
//import { TabNavigation } from '../components/blocks/tabs/tabNavigation';
//import { Header } from '../components/blocks/header/header';
import { FC, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../contexts/currentUser";
import { Navigate } from "react-router-dom";
import {PageContainer, PageContent, PageHeader} from "../components/PageComponents";

export interface Props {
    title: string;
    tabNavigation: boolean;
    header?: boolean;
    checkAuth?: boolean;
    children?: React.ReactNode;
}

const defaultProps: Props = {
    title: '',
    header: true,
    tabNavigation: false,
    checkAuth: true,
    children: null
}

export const Page: FC<Props> = (props): JSX.Element => {

    const [currentUserState] = useContext(CurrentUserContext);
    const [redirectLogin, setRedirectLogin] = useState(false);

    useEffect(() => {
        document.title = props.title
    }, []);

    useEffect(() => {
        setRedirectLogin(currentUserState.isLoggedIn === false);
        if (props.checkAuth === false) {
            setRedirectLogin(false);
        }
    }, [currentUserState]);


    return (
        <PageContainer>
            {redirectLogin ? <Navigate to={'/login'} /> : null }
            {props.header && <PageHeader name={currentUserState.isLoggedIn && ((currentUserState.currentUser && currentUserState.currentUser.first_name) + ' ' + (currentUserState.currentUser && currentUserState.currentUser.last_name))}/>}
            <PageContent header={props.header} footer={props.tabNavigation}>
                {props.children}
            </PageContent>
        </PageContainer>
    );
}

Page.defaultProps = defaultProps;